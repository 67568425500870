import { FbTimestamp } from '@/lib/FB';
import { TermDoc, TermModel, TermSnap } from '@/lib/models/TermModel';
import { StateService } from '@/lib/StateService';
import { firestoreSubscribeList } from '@/lib/Util';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

interface State {
  readonly snapshots: Observable<TermSnap[]>;
}

/** manager term service */
export class TermService implements StateService<State> {
  readonly parseRegex = /([\d\.]+)\s*\/\s*(.*)/i;

  // store mutable snapshot list
  readonly #source = {
    snapshots: new BehaviorSubject<TermSnap[]>([]),
  };

  readonly state: State;

  constructor() {
    // snapshots read-only
    const snapshots = this.#source.snapshots.asObservable();

    this.state = {
      snapshots,
    };
  }

  listen() {
    const m = new TermModel();
    const q = m.collection;
    return firestoreSubscribeList(q).pipe(
      tap((docs) => this.#source.snapshots.next(docs)),
    );
  }

  async addTerm(content: string, percent: number) {
    const doc: TermDoc = {
      content,
      percent,
      created_at: FbTimestamp.fromDate(new Date()),
    };
    const m = new TermModel();
    return m.collection.add(doc);
  }

  async updateTerm(id: string, partial: Partial<TermDoc>) {
    const m = new TermModel();
    return m.collection.doc(id).update(partial);
  }

  async removeTerm(id: string) {
    const m = new TermModel();
    return m.collection.doc(id).delete();
  }

  /** parse custom string, return percent and content if validate */
  parseInputStr(contentText: string): { percent?: number; content?: string } {
    const match = contentText.match(this.parseRegex) ?? [];

    // break line to 2 parts
    const [, percentStr = '', contentStr = ''] = match;

    const percent = Number(percentStr.trim());
    const content = contentStr.trim();

    if (isNaN(percent) || !content.length) return {};

    return {
      percent,
      content,
    };
  }
}
