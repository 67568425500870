const firebaseConfig = {
  apiKey: 'AIzaSyCEysTozBt1OdHpCLfTnyxZES0S_O_RBI0',
  authDomain: 'intrabuild-report.firebaseapp.com',
  projectId: 'intrabuild-report',
  storageBucket: 'intrabuild-report.appspot.com',
  messagingSenderId: '280263686309',
  appId: '1:280263686309:web:fce4653f4751171e1bb54c',
};

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

export const fba = (() => {
  if (firebase.apps.length) return firebase.app();
  return firebase.initializeApp(firebaseConfig);
})();

export const fbf = fba.firestore();

export interface FDoc extends firebase.firestore.DocumentData {}
export interface FSnap<T> extends firebase.firestore.DocumentSnapshot<T> {}
export interface FCollection<T>
  extends firebase.firestore.CollectionReference<T> {}

export const FbTimestamp = firebase.firestore.Timestamp;

// authenticate base
export const fauth = fba.auth();
