import 'tailwindcss/tailwind.css';
import 'buefy/dist/buefy.css';
import '@/style/style.scss';

import './registerServiceWorker';

import Vue from 'vue';
import vca from '@vue/composition-api';
Vue.use(vca);

import makeRouter from './router';

import Buefy from 'buefy';

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.use(Buefy);

import App from './App.vue';

new Vue({
  router: makeRouter(),
  render: h => h(App),
}).$mount('#app');
