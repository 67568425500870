import { CiService } from '@/lib/Ci';
import { DivisionDoc } from '@/lib/models/DivisionModel';
import {
  ProposalDivision,
  ProposalDivisionCalType,
  ProposalDivisionLine,
} from '@/lib/models/ProposalModel';
import { StateService } from '@/lib/StateService';
import { uniqueId, utilDeepClone } from '@/lib/Util';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, map, shareReplay, take } from 'rxjs/operators';
import { DivisionListService } from '../division/DivisionListService';
import { ProposalDetailService } from './ProposalDetailService';

interface State {
  readonly divisions: Observable<ProposalDivision[]>;
}

export type ProposalDivisionUpdateDto = Partial<
  Pick<ProposalDivision, 'amount' | 'lines' | 'offset' | 'name' | 'cal_type'>
>;

export type ProposalDivisionLineUpdateDto = Partial<
  Pick<ProposalDivisionLine, 'amount' | 'content' | 'offset'>
>;

export class ProposalDivisionService implements StateService<State> {
  readonly state: State;

  // tap proposal detail service for use.
  #proposalDetailService: ProposalDetailService;
  #divisionSerivce: DivisionListService;

  constructor(ci: CiService) {
    this.#proposalDetailService = ci.ProposalDetailService;
    this.#divisionSerivce = ci.DivisionListService;

    const divisions = this.#proposalDetailService.state.doc.pipe(
      map((doc) => doc?.divisions ?? []),
    );

    this.state = {
      divisions,
    };
  }

  // reset offset divisions list follow order
  private _resetOffset(divis: ProposalDivision[]) {
    divis.forEach((divi, inx) => (divi.offset = inx));
    return divis;
  }

  // reset offset division lines follow order.
  private _resetOffsetLine(lines: ProposalDivisionLine[]) {
    lines.forEach((line, inx) => (line.offset = inx));
    return lines;
  }

  // return new copy array divisions
  private _copyDivis() {
    return this.state.divisions.pipe(
      take(1),
      map((list) => [...list]),
    );
  }

  // return division has __id match with param.
  private _getDiviBy__id(divis: ProposalDivision[], __id: string) {
    const inx = divis.findIndex((d) => d.__id === __id);
    if (inx === -1) throw new Error(`Not found division #${__id} in proposal.`);
    return {
      index: inx,
      divi: divis[inx],
    };
  }

  /**
   * assign new division
   */
  async add(divisionId?: string, name?: string) {
    const divis = await this._copyDivis().toPromise();
    const __id = uniqueId(divis.map((d) => d.__id));

    // create new division base from division document
    if (!!divisionId) {
      const diviSnap = await this.#divisionSerivce.state.snapshots
        .pipe(
          take(1),
          map((list) => list.find((d) => d.id === divisionId)),
          concatMap((snap) =>
            snap
              ? of(snap)
              : throwError(
                  new Error(`Division document #${divisionId} not founed.`),
                ),
          ),
        )
        .toPromise();

      const diviDoc = diviSnap.data() as DivisionDoc;

      const doc: ProposalDivision = {
        id: divisionId,
        __id,
        name: diviDoc.name,
        amount: 0,
        offset: divis.length,
        cal_type: ProposalDivisionCalType.Simple,
        lines: [],
      };
      divis.push(doc);
      return this.#proposalDetailService.update({ divisions: divis });
    }
    // create new proposal division from custom name
    else if (!!name) {
      const doc: ProposalDivision = {
        id: undefined,
        __id,
        name,
        amount: 0,
        offset: divis.length,
        cal_type: ProposalDivisionCalType.Simple,
        lines: [],
      };
      divis.push(doc);
      return this.#proposalDetailService.update({ divisions: divis });
    }

    throw new Error(
      'Create new proposal division require division document id or custom name',
    );
  }

  /**
   * update division
   */
  async update(__id: string, dto: ProposalDivisionUpdateDto) {
    const divis = await this._copyDivis().toPromise();
    const { divi, index } = this._getDiviBy__id(divis, __id);
    const newDivi = { ...divi, ...dto };
    divis.splice(index, 1, newDivi);
    this._resetOffset(divis);
    return this.#proposalDetailService.update({ divisions: divis });
  }

  /**
   * remove division
   */
  async remove(__id: string) {
    console.log(__id);
    const divis = await this._copyDivis().toPromise();
    const { index } = this._getDiviBy__id(divis, __id);
    divis.splice(index, 1);
    this._resetOffset(divis);
    return this.#proposalDetailService.update({ divisions: divis });
  }

  /**
   * calculate division amount base on lines's amount.
   */
  calculateAmount(lines: ProposalDivisionLine[]) {
    const sum: number = lines.reduce((_sum, line) => _sum + line.amount, 0);
    return sum;
  }

  /**
   * update division amount
   */
  async updateAmount(__id: string, amount: number) {
    const divis = await this._copyDivis().toPromise();
    const { divi, index } = this._getDiviBy__id(divis, __id);
    const newDivi = utilDeepClone(divi);

    newDivi.amount = amount;
    divis.splice(index, 1, newDivi);

    return this.#proposalDetailService.update({ divisions: divis });
  }

  /** sort division */
  async sort(oindex?: number, nindex?: number) {
    if (oindex == void 0 || nindex == void 0) return;

    const divis = await this._copyDivis().toPromise();
    const [item] = divis.splice(oindex, 1);
    divis.splice(nindex, 0, item);
    this._resetOffset(divis);
    return this.#proposalDetailService.update({ divisions: divis });
  }

  /**
   * add new line to division
   */
  async addLine(divi__id: string, content: string) {
    const divis = await this._copyDivis().toPromise();
    const { divi, index } = this._getDiviBy__id(divis, divi__id);
    const newDivi = utilDeepClone(divi);

    const lineDoc: ProposalDivisionLine = {
      __id: uniqueId(newDivi.lines.map((d) => d.__id)),
      offset: newDivi.lines.length,
      amount: 0,
      content,
    };
    newDivi.lines.push(lineDoc);
    this._resetOffsetLine(newDivi.lines);

    divis.splice(index, 1, newDivi);
    return this.#proposalDetailService.update({ divisions: divis });
  }

  /**
   * update line
   */
  async updateLine(
    divi__id: string,
    line__id: string,
    dto: ProposalDivisionLineUpdateDto,
  ) {
    const divis = await this._copyDivis().toPromise();
    const { divi, index } = this._getDiviBy__id(divis, divi__id);
    const newDivi = utilDeepClone(divi);

    const lineIndex = newDivi.lines.findIndex((d) => d.__id === line__id);
    if (lineIndex === -1) throw new Error(`Not found line #${line__id}.`);
    const newLine = { ...newDivi.lines[lineIndex], ...dto };

    newDivi.lines.splice(lineIndex, 1, newLine);
    this._resetOffsetLine(newDivi.lines);
    divis.splice(index, 1, newDivi);

    return this.#proposalDetailService.update({ divisions: divis });
  }

  /**
   * update line amount
   */
  async updateLineAmount(divi__id: string, line__id: string, amount: number) {
    const divis = await this._copyDivis().toPromise();
    const { divi, index } = this._getDiviBy__id(divis, divi__id);

    const lineInx = divi.lines.findIndex((d) => d.__id === line__id);
    if (lineInx === -1) throw new Error(`Not found line #${line__id}.`);

    const newDivi = utilDeepClone(divi);
    const line = newDivi.lines[lineInx];

    line.amount = amount;

    // when division cal_type is advance we update division amount
    if (newDivi.cal_type === ProposalDivisionCalType.Advance) {
      newDivi.amount = this.calculateAmount(newDivi.lines);
      console.log(newDivi.amount);
    }

    divis.splice(index, 1, newDivi);

    return this.#proposalDetailService.update({ divisions: divis });
  }

  /**
   * remove line
   */
  async removeLine(divi__id: string, line__id: string) {
    const divis = await this._copyDivis().toPromise();
    const { divi, index } = this._getDiviBy__id(divis, divi__id);
    const newDivi = utilDeepClone(divi);

    const lineIndex = newDivi.lines.findIndex((d) => d.__id === line__id);
    if (lineIndex === -1) throw new Error(`Not found line #${line__id}.`);

    newDivi.lines.splice(lineIndex, 1);
    this._resetOffsetLine(newDivi.lines);

    divis.splice(index, 1, newDivi);
    return this.#proposalDetailService.update({ divisions: divis });
  }

  /**
   * sort line
   */
  async sortLine(divi__id: string, oldIndex?: number, newIndex?: number) {
    if (oldIndex == void 0 || newIndex == void 0) return;

    const divis = await this._copyDivis().toPromise();
    const { divi, index } = this._getDiviBy__id(divis, divi__id);
    const newDivi = utilDeepClone(divi);

    const [item] = newDivi.lines.splice(oldIndex, 1);
    newDivi.lines.splice(newIndex, 0, item);
    this._resetOffsetLine(newDivi.lines);

    divis.splice(index, 1, newDivi);
    return this.#proposalDetailService.update({ divisions: divis });
  }
}
