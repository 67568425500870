import { BaseModel } from '../BaseModel';
import { FDoc, FbTimestamp, fbf, FSnap, FCollection } from '../FB';

export interface DivisionDoc extends FDoc {
  name: string;
  created_at: firebase.default.firestore.Timestamp;
  lines: string[];
}

export interface DivisionSnapshot extends FSnap<DivisionDoc> {}
export interface DivisionCollection extends FCollection<DivisionDoc> {}

export class DivisionModel extends BaseModel<DivisionDoc> {
  readonly collection = fbf.collection('divisions') as DivisionCollection;
}
