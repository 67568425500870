import { useCiService } from '@/lib/Ci';
import { take, tap } from 'rxjs/operators';
import { NavigationGuard } from 'vue-router';

export const RouterGuardProtected: NavigationGuard = async (to, from, next) => {
  const authS = useCiService('AuthService');

  // get current authenticate event
  const authEvent = authS.eventChannel().pipe(take(1));
  const event = await authEvent.toPromise();

  const wantSigninPage = to.name === 'signin';
  const signed = !!event;

  // signed and not want signin page then pass
  if (signed && !wantSigninPage) return next();
  // signed and want access signin page, should fallback to home url.
  if (signed && wantSigninPage) return next('/');
  // not signed and access signin page
  if (!signed && wantSigninPage) return next();
  // not signed and access other pages
  if (!signed && !wantSigninPage) return next({ name: 'signin' });

  // authEvent
  //   .pipe(
  //     tap((event) => {
  //       console.log(event, wantSigninPage);
  //       // not signin, want access protected page
  //       if (!event && !wantSigninPage) {
  //         console.log('case 1');
  //         next({ name: 'signin' });
  //       }
  //       // not sign in, want to signin page
  //       else if (!event && wantSigninPage) {
  //         console.log('case 2');
  //         next();
  //       }
  //       // signed, want to protected page
  //       else if (event && !wantSigninPage) {
  //         console.log('case 3');
  //         next();
  //       }
  //       // signed, want signin page
  //       else if (event && wantSigninPage) {
  //         console.log('case 4');
  //         next({ name: 'proposal' });
  //       }
  //       // unexpected
  //       else next(new Error('Unexpected auth status.'));
  //     }),
  //   )
  //   .subscribe();
};
