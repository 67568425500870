import { CiService } from '@/lib/Ci';
import { ProposalDoc } from '@/lib/models/ProposalModel';
import { StateService } from '@/lib/StateService';
import { Observable } from 'rxjs';
import { map, share, shareReplay } from 'rxjs/operators';
import { ProposalDetailService } from './ProposalDetailService';

export interface ProposalSummary {
  subtotal: number;
  overhead: number;
  profit: number;
  total: number;
}

interface State {
  summary: Observable<ProposalSummary>;
}

/**
 * manager summary data within one proposal
 */
export class ProposalSummaryService implements StateService<State> {
  readonly state: State;

  #proposalDetailService: ProposalDetailService;

  constructor(ci: CiService) {
    this.#proposalDetailService = ci.ProposalDetailService;

    // calculate proposal summary
    const summary = this.#proposalDetailService.state.doc.pipe(
      map((doc) => this._calculate(doc)),
      shareReplay(1),
    );

    this.state = { summary };
  }

  sample(): ProposalSummary {
    return {
      subtotal: 0,
      overhead: 0,
      profit: 0,
      total: 0,
    };
  }

  private _calculate(proposalDoc: ProposalDoc | null) {
    if (!proposalDoc) return this.sample();

    const overheadPercent = proposalDoc.overhead_percent ?? 7;
    const profitPercent = proposalDoc.profit_percent ?? 8;

    const d = this.sample();
    d.subtotal = proposalDoc.divisions.reduce((sum, d) => sum + d.amount, 0);
    d.overhead = d.subtotal * (overheadPercent / 100);
    d.profit = d.subtotal * (profitPercent / 100);

    d.total = d.subtotal + d.overhead + d.profit;
    return d;
  }
}
