import { BaseModel } from '../BaseModel';
import { fbf, FCollection, FDoc, FSnap } from '../FB';

export interface ProposalDoc extends FDoc {
  client_id: string;
  created_at: firebase.default.firestore.Timestamp;
  date: firebase.default.firestore.Timestamp;
  name: string;
  revision: number;
  overhead_percent: number;
  profit_percent: number;
  receiver_label: string;
  receiver: ProposalReceiver;
  divisions: ProposalDivision[];
  terms: ProposalTerm[];
  attention_label: string;
  attention: string;
  note_label: string;
  note: string;
}

export interface ProposalReceiver {
  name: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
}

export interface ProposalDivision {
  id?: string;
  __id: string;
  name: string;
  offset: number;
  lines: ProposalDivisionLine[];
  amount: number;
  cal_type: ProposalDivisionCalType;
}

export enum ProposalDivisionCalType {
  Simple = 'Simple',
  Advance = 'Advance',
}

export interface ProposalDivisionLine {
  __id: string;
  offset: number;
  content: string;
  amount: number;
}

export interface ProposalTerm {
  __id: string;
  offset: number;
  content: string;
  percent: number;
}

export interface ProposalSnapshot extends FSnap<ProposalDoc> {}

export interface ProposalCollection extends FCollection<ProposalDoc> {}

export class ProposalModel extends BaseModel<ProposalDoc> {
  readonly collection = fbf.collection('proposals') as ProposalCollection;
}
