import { CiService } from '@/lib/Ci';
import { ProposalTerm } from '@/lib/models/ProposalModel';
import { StateService } from '@/lib/StateService';
import { generateId, uniqueId } from '@/lib/Util';
import { combineLatest, Observable } from 'rxjs';
import { map, share, shareReplay, take, tap, timestamp } from 'rxjs/operators';
import { ProposalDetailService } from './ProposalDetailService';
import { ProposalSummaryService } from './ProposalSummaryService';

interface State {
  /** term content from proposal detail */
  terms: Observable<ProposalTerm[]>;
  termAmounts: Observable<ProposalTermAmount[]>;
}

export interface ProposalTermAmount {
  id: string;
  amount: number;
}

export class ProposalTermService implements StateService<State> {
  readonly state: State;

  #proposalDetailService: ProposalDetailService;
  #proposalSummaryService: ProposalSummaryService;

  constructor(ci: CiService) {
    this.#proposalDetailService = ci.ProposalDetailService;
    this.#proposalSummaryService = ci.ProposalSummaryService;

    // create term
    const terms = this.#proposalDetailService.state.doc.pipe(
      map((doc) => doc?.terms ?? []),
      shareReplay(1),
    );

    // calculate amount base on percent of term
    const termAmounts = combineLatest([
      this.#proposalSummaryService.state.summary,
      terms,
    ]).pipe(
      map(([summary, terms]) => {
        const total = summary.total;
        return terms.map(
          (term): ProposalTermAmount => {
            return {
              amount: (total * term.percent) / 100,
              id: term.__id,
            };
          },
        );
      }),
      shareReplay(1),
    );

    this.state = {
      terms,
      termAmounts,
    };
  }

  // get once time term list
  private _pickTerms() {
    return this.state.terms.pipe(
      take(1),
      map((list) => [...list]),
    );
  }

  // convert offset term list follow order
  private _reassignOffset(terms: ProposalTerm[]) {
    terms.forEach((term, order) => {
      term.offset = order;
    });
    return terms;
  }

  /**
   * add new term into proposal
   */
  async add(dto: Omit<ProposalTerm, '__id' | 'offset'>) {
    // clone new list term
    const terms = await this._pickTerms().toPromise();

    const id = uniqueId(terms.map((d) => d.__id));

    const doc: ProposalTerm = {
      ...dto,
      offset: terms.length,
      __id: id,
    };

    terms.push(doc);
    this._reassignOffset(terms);
    return this.#proposalDetailService.update({ terms });
  }

  /**
   * update term in proposal
   */
  async update(id: string, dto: Partial<ProposalTerm>) {
    // clone new list term
    const terms = await this._pickTerms().toPromise();

    const inx = terms.findIndex((d) => d.__id === id);
    if (inx === -1) throw new Error(`Now found proposal term #${id}`);

    const newTerm = { ...terms[inx], ...dto };
    terms.splice(inx, 1, newTerm);
    this._reassignOffset(terms);
    return this.#proposalDetailService.update({ terms });
  }

  /**
   * remove term in proposal
   */
  async remove(id: string) {
    // clone new list term
    const terms = await this._pickTerms().toPromise();

    const inx = terms.findIndex((d) => d.__id === id);
    if (inx === -1) throw new Error('Now found proposal term #$id}');

    terms.splice(inx, 1);
    this._reassignOffset(terms);
    return this.#proposalDetailService.update({ terms });
  }

  /**
   * sorting terms in proposal
   */
  async sort(oldOffset: number, newOffset: number) {
    const terms = await this._pickTerms().toPromise();
    const oldInx = terms.findIndex((d) => d.offset === oldOffset);
    const newInx = terms.findIndex((d) => d.offset === newOffset);

    const [item] = terms.splice(oldInx, 1);
    terms.splice(newInx, 0, item);
    this._reassignOffset(terms);
    return this.#proposalDetailService.update({ terms });
  }
}
