import { onBeforeMount, onBeforeUnmount, Ref, ref } from '@vue/composition-api';
import numberal from 'numeral';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import type firebase from 'firebase';
import { DateTime } from 'luxon';

export const utilDeepClone = <T = any>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const utilFmtCurrency = (cur: number) => {
  const fmt = '$0,0[.]00';
  return numberal(cur).format(fmt);
};

export const utilFmtDate = (date: Date) => {
  const fmt = DateTime.DATE_SHORT;
  return DateTime.fromJSDate(date).toLocaleString(fmt);
};

export const utilFmtPercent = (num: number, divide = false) => {
  if (divide) return `${num * 100}%`;
  return `${num}%`;
};

export const generateId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

/**
 * create random id make sure not duplicate with any
 * id from compare list
 */
export const uniqueId = (ids: string[]) => {
  let id: string;
  do {
    id = generateId();
  } while (ids.includes(id));
  return id;
};

/** util map rxjs observable object to vue ref object */
export function subscribeTo<T, I = T, R = I extends T ? T : I | T>(
  init: I,
  source: Observable<T>,
): Readonly<Ref<R>> {
  const target = ref(init);
  const chain = source.pipe(tap((d) => (target.value = d as any)));
  let sub: Subscription;
  onBeforeMount(() => (sub = chain.subscribe()));
  onBeforeUnmount(() => sub.unsubscribe);
  return target as Ref<R>;
}

export function firestoreSubscribeDoc<T>(
  source: firebase.firestore.DocumentReference<T>,
) {
  return new Observable<firebase.firestore.DocumentSnapshot<T>>((out) => {
    const sub = source.onSnapshot({
      next: (q) => out.next(q),
      error: (e) => out.error(e),
      complete: () => out.complete(),
    });

    return () => {
      sub();
    };
  });
}

export function firestoreSubscribeList<T>(source: firebase.firestore.Query<T>) {
  return new Observable<firebase.firestore.QueryDocumentSnapshot<T>[]>(
    (out) => {
      const sub = source.onSnapshot({
        next: (q) => out.next(q.docs),
        error: (e) => out.error(e),
        complete: () => out.complete(),
      });

      return () => {
        sub();
      };
    },
  );
}
