import { fauth } from '@/lib/FB';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/**
 * manager account and accessibility
 */
export class AuthService {
  // add auth provider
  #provider = new firebase.auth.GoogleAuthProvider();

  //#region sign in with popup
  /**
   * sign in to system thought popup
   */
  async signinWithPopup() {
    return fauth.signInWithPopup(this.#provider);
  }
  //#endregion

  //#endregion sign in with redirect
  /**
   * signin use redirect
   */
  async signinWithRedirect() {
    return fauth.signInWithRedirect(this.#provider);
  }

  /**
   * return lastest sign in result from redirect action.
   */
  async getSigninRedirectResult() {
    return fauth.getRedirectResult();
  }
  //#endregion

  /**
   * subscribe into firebase authenticate channel
   */
  #eventChannelPipe = new Observable<firebase.User>((out) => {
    const sub = fauth.onAuthStateChanged({
      next: (user) => out.next(user),
      error: (err) => out.error(err),
      complete: () => out.complete(),
    });
    return () => sub();
  }).pipe(shareReplay(1));

  eventChannel() {
    return this.#eventChannelPipe;
  }
}
