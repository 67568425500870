/* eslint-disable no-console */

import { SnackbarProgrammatic } from 'buefy';
import { BNoticeComponent } from 'buefy/types/components';
import { register } from 'register-service-worker';

let downloadingContentSnack: BNoticeComponent;

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');

      downloadingContentSnack = SnackbarProgrammatic.open({
        indefinite: true,
        type: 'is-info',
        position: 'is-bottom-right',
        message: `New content is downloading, please wait...`,
        queue: false,
        // user no need do any action, and we need this message
        // stay until download done
        duration: 99999,
      });
    },
    updated(registration) {
      console.log('New content is available; please refresh.');

      downloadingContentSnack?.close();

      SnackbarProgrammatic.open({
        indefinite: true,
        type: 'is-success',
        position: 'is-bottom-right',
        message: `An update available, please refresh to apply.`,
        actionText: 'Refresh',
        // @ts-ignore
        cancelText: 'stay little longer',
        onAction: () => {
          window.location.reload();
        },
        queue: false,
      });
    },

    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );

      SnackbarProgrammatic.open({
        type: 'is-warning',
        message:
          'No internet connection found. App is running in offline mode.',
      });
    },
    error(error) {
      console.error('Error during service worker registration:', error);

      SnackbarProgrammatic.open({
        type: 'is-danger',
        message: 'Services will be available soon, please stay calm...',
      });
    },
  });
}
