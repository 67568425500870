import { asClass, createContainer } from 'awilix';
import { AuthService } from './modules/auth/AuthService';
import { ClientService } from './modules/client/ClientService';
import { DivisionListService } from './modules/division/DivisionListService';
import { ProposalDetailService } from './modules/proposal/ProposalDetailService';
import { ProposalDivisionService } from './modules/proposal/ProposalDivisionService';
import { ProposalListService } from './modules/proposal/ProposalListService';
import { ProposalReceiverService } from './modules/proposal/ProposalReceiverService';
import { ProposalSummaryService } from './modules/proposal/ProposalSummaryService';
import { ProposalTermService } from './modules/proposal/ProposalTermService';
import { SearchEngineService } from './modules/search-engine/SearchEngineService';
import { SettingService } from './modules/setting/SettingService';
import { TermService } from './modules/term/TermService';

export interface CiService {
  DivisionListService: DivisionListService;

  ProposalListService: ProposalListService;
  ProposalDetailService: ProposalDetailService;
  ProposalDivisionService: ProposalDivisionService;
  ProposalReceiverService: ProposalReceiverService;
  ProposalSummaryService: ProposalSummaryService;
  ProposalTermService: ProposalTermService;

  ClientService: ClientService;
  SearchEngineService: SearchEngineService;
  SettingService: SettingService;
  TermService: TermService;

  AuthService: AuthService;
}

const container = createContainer<CiService>({
  injectionMode: 'PROXY',
});

container.register({
  DivisionListService: asClass(DivisionListService).singleton(),

  ProposalListService: asClass(ProposalListService).singleton(),
  ProposalDetailService: asClass(ProposalDetailService).singleton(),
  ProposalDivisionService: asClass(ProposalDivisionService).singleton(),
  ProposalReceiverService: asClass(ProposalReceiverService).singleton(),
  ProposalSummaryService: asClass(ProposalSummaryService).singleton(),
  ProposalTermService: asClass(ProposalTermService).singleton(),

  ClientService: asClass(ClientService).singleton(),
  SearchEngineService: asClass(SearchEngineService).singleton(),

  SettingService: asClass(SettingService).singleton(),
  TermService: asClass(TermService).singleton(),

  AuthService: asClass(AuthService).singleton(),
});

export const useCiService = <T extends keyof CiService>(
  key: T,
): CiService[T] => {
  return container.resolve(key);
};
