

































































































































import { defineComponent } from '@vue/composition-api';

// <!-- http://samherbert.net/svg-loaders/ -->
// <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->

export default defineComponent({
  setup() {
    const s = {};
    return s;
  },
});
