import { ProposalDoc, ProposalReceiver } from '@/lib/models/ProposalModel';

/** manager proposal receiver */
export class ProposalReceiverService {
  fromProposalDoc(doc: ProposalDoc | null): ProposalReceiver {
    if (!!doc?.receiver) return doc.receiver;
    return this.sample();
  }

  sample(): ProposalReceiver {
    return {
      city: 'Sample city',
      state: 'Sample state',
      name: 'Mr. Joe',
      street: 'Sample street',
      zipcode: '000',
    };
  }
}
