import { ClientDoc, ClientModel } from '@/lib/models/ClientModel';
import { StateService } from '@/lib/StateService';
import { firestoreSubscribeDoc } from '@/lib/Util';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface State {
  readonly id: Observable<string>;
  readonly doc: Observable<ClientDoc | null>;
}

export class ClientService implements StateService<State> {
  readonly state: State;

  // store client document
  readonly #source = {
    doc: new BehaviorSubject<ClientDoc | null>(null),
    id: of(ClientModel.id),
  };

  constructor() {
    // build state
    this.state = {
      id: this.#source.id,
      doc: this.#source.doc.asObservable(),
    };
  }

  listen() {
    const id = ClientModel.id;
    const q = new ClientModel().collection.doc(id);

    return firestoreSubscribeDoc(q).pipe(
      map(snapshot => snapshot.data() ?? null),
      tap(doc => this.#source.doc.next(doc)),
    );
  }
}
