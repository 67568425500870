import { RouteConfig } from 'vue-router';
import { RouterGuardProtected } from './guard/RouterGuardProtected';

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'proposal',
    component: () => import('@/views/ProposalView.vue'),
    beforeEnter: RouterGuardProtected,
  },
  {
    path: '/divisions',
    name: 'division',
    component: () => import('@/views/DivisionView.vue'),
    beforeEnter: RouterGuardProtected,
  },
  {
    path: '/terms',
    name: 'term',
    component: () => import('@/views/TermView.vue'),
    beforeEnter: RouterGuardProtected,
  },
  {
    path: '/proposal/:proposalId',
    name: 'proposal.detail',
    component: () => import('../views/ProposalDetailView.vue'),
    beforeEnter: RouterGuardProtected,
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/SigninView.vue'),
  },
];
