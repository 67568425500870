import { useCiService } from '@/lib/Ci';
import { FbTimestamp } from '@/lib/FB';
import { ClientModel } from '@/lib/models/ClientModel';
import {
  ProposalDoc,
  ProposalModel,
  ProposalSnapshot,
} from '@/lib/models/ProposalModel';
import { ref } from '@vue/composition-api';
import { Observable } from 'rxjs';

interface State {
  snapshots: ProposalSnapshot[];
}

/** manager proposal list */
export class ProposalListService {
  readonly state = ref<State>({
    snapshots: [],
  });

  listen() {
    return new Observable<void>((out) => {
      const q = new ProposalModel().collection.orderBy('created_at', 'desc');
      return q.onSnapshot({
        next: (q) => {
          this.state.value.snapshots = q.docs;
          out.next();
        },
        error: (err) => out.error(err),
        complete: () => out.complete(),
      });
    });
  }

  async create(name: string) {
    const date = FbTimestamp.fromDate(new Date());
    const proposalReceiverService = useCiService('ProposalReceiverService');

    const doc: ProposalDoc = {
      client_id: ClientModel.id,
      created_at: date,
      date,
      divisions: [],
      terms: [],
      name,
      revision: 0,
      overhead_percent: 7,
      profit_percent: 8,
      note_label: 'note',
      attention_label: 'attention',
      receiver_label: 'prepared for',
      note: '',
      attention: '',
      receiver: proposalReceiverService.sample(),
    };
    return new ProposalModel().collection.add(doc);
  }

  async remove(id: string) {
    return new ProposalModel().collection.doc(id).delete();
  }
}
