import { BaseModel } from '../BaseModel';
import { fbf, FCollection, FDoc, FSnap } from '../FB';

export interface ClientDoc extends FDoc {
  name: string;
  tel: string;
  fax: string;
  address: {
    city: string;
    state: string;
    street: string;
    zipcode: string;
  };
}

export interface ClientSnapshot extends FSnap<ClientDoc> {}

export interface ClientCollection extends FCollection<ClientDoc> {}

export class ClientModel extends BaseModel<ClientDoc> {
  readonly collection = fbf.collection('clients') as ClientCollection;

  static id = 'nzmWnxwhSUPIeQWl5619';
}
