







































































import { useCiService } from '@/lib/Ci';
import { fauth } from '@/lib/FB';
import { subscribeTo } from '@/lib/Util';
import { useRouter } from '@/router/use-router';
import { computed, defineComponent } from '@vue/composition-api';
import { ToastProgrammatic } from 'buefy';
import IntrabuildLogo from '../IntrabuildLogo.vue';

export default defineComponent({
  components: { IntrabuildLogo },
  setup() {
    const settingService = useCiService('SettingService');
    const router = useRouter();

    const toggleDivisionLineAmount = subscribeTo(
      false,
      settingService.state.toggleDivisionLineAmount,
    );

    const setToggleDivisionLineAmount = (v: boolean) =>
      settingService.state.toggleDivisionLineAmount.next(v);

    const isProposalDetail = computed(
      () => router.currentRoute.name === 'proposal.detail',
    );

    const print = () => {
      window.print();
    };

    const signout = async () => {
      await fauth.signOut();
      router.push({ name: 'signin' });
      ToastProgrammatic.open({
        type: 'is-success',
        message: `Good bye!`,
        queue: false,
        position: 'is-top-right',
      });
    };

    const s = {
      isProposalDetail,
      print,
      toggleDivisionLineAmount,
      setToggleDivisionLineAmount,
      signout,
    };
    return s;
  },
});
