import { StateService } from '@/lib/StateService';
import { BehaviorSubject } from 'rxjs';

interface State {
  toggleDivisionLineAmount: BehaviorSubject<boolean>;
}

/** control common values need provide cross pages/component */
export class SettingService implements StateService<State> {
  readonly state: State;

  constructor() {
    // division line price show/hide
    const toggleDivisionLineAmount = new BehaviorSubject(false);

    this.state = {
      toggleDivisionLineAmount,
    };
  }
}
