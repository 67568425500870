import { BaseModel } from '../BaseModel';
import { fbf, FCollection, FDoc, FSnap } from '../FB';

export interface TermDoc extends FDoc {
  content: string;
  created_at: firebase.default.firestore.Timestamp;
  percent: number;
}

export interface TermSnap extends FSnap<TermDoc> {}
export interface TermCollection extends FCollection<TermDoc> {}

export class TermModel extends BaseModel<TermDoc> {
  readonly collection = fbf.collection('terms') as TermCollection;
}
