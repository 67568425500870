import { inject, provide, reactive } from '@vue/composition-api';
import VueRouter from 'vue-router';
import getRouter from './index';

const RouterSymbol = Symbol();

export function provideRouter() {
  provide(RouterSymbol, reactive(getRouter()));
}

export function useRouter() {
  const router = inject(RouterSymbol);
  if (!router) {
    // throw error, no store provided
    throw new Error('Provide router to Vue env incorrect.');
  }
  return router as VueRouter;
}
