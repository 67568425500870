import {
  ProposalDoc,
  ProposalModel,
  ProposalReceiver,
} from '@/lib/models/ProposalModel';
import { firestoreSubscribeDoc } from '@/lib/Util';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import {
  bufferTime,
  concatMap,
  filter,
  finalize,
  map,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { StateService } from '@/lib/StateService';
import { CiService } from '@/lib/Ci';

interface StateRaw {
  readonly doc: Observable<ProposalDoc | null>;
  readonly id: Observable<string | null>;
  readonly receiver: Observable<ProposalReceiver>;
}

export type ProposalDetailUpdateDto = Partial<
  Exclude<ProposalDoc, 'client_id' | 'created_at'>
>;

/** manager proposal for single page. */
export class ProposalDetailService implements StateService<StateRaw> {
  // create source
  readonly #source = {
    doc: new BehaviorSubject<ProposalDoc | null>(null),
    id: new BehaviorSubject<string | null>(null),
  };

  readonly state: StateRaw;

  constructor(ci: CiService) {
    const proposalReceiverService = ci.ProposalReceiverService;

    const doc = this.#source.doc.asObservable();
    const id = this.#source.id.asObservable();

    // receiver
    const receiver = doc.pipe(
      map((doc) => proposalReceiverService.fromProposalDoc(doc)),
      share(),
    );

    this.state = { doc, id, receiver };
  }

  setId(id: string) {
    this.#source.id.next(id);
  }

  /** setup realtime update point to proposal document */
  listen() {
    const id$ = this.state.id;

    return id$.pipe(
      concatMap((id) => {
        if (!id) return EMPTY;

        const q = new ProposalModel().collection.doc(id);
        return firestoreSubscribeDoc(q).pipe(
          map((r) => r.data() ?? null),
          tap((doc) => this.#source.doc.next(doc)),
        );
      }),
      finalize(() => {
        this.#source.id.next(null);
        this.#source.doc.next(null);
      }),
    );
  }

  // lazy update proposal document
  //#region
  #updateSubject = new Subject<ProposalDetailUpdateDto>();
  #updatePipe = this.#updateSubject.pipe(
    bufferTime(10),
    filter((size) => !!size.length),
    concatMap(async (dtos) => {
      // merge dtos into single dto, newer version override older.
      const dto = dtos.reduce(
        (o, d) => Object.assign({}, o, d),
        {},
      ) as ProposalDetailUpdateDto;

      const id = (await this.state.id.pipe(take(1)).toPromise()) as string;
      return new ProposalModel().collection.doc(id).update(dto);
    }),
    share(),
  );
  #updatePipeSub = this.#updatePipe.subscribe();

  /**
   * update proposal document
   */
  async update(dto: ProposalDetailUpdateDto) {
    setTimeout(() => this.#updateSubject.next(dto));
    return this.#updatePipe.pipe(take(1)).subscribe();
  }
  //#endregion

  async updateOverheadPercent(num: number) {
    return this.update({ overhead_percent: num });
  }

  async updateProfitPercent(num: number) {
    return this.update({ profit_percent: num });
  }
}
