import { FbTimestamp } from '@/lib/FB';
import {
  DivisionDoc,
  DivisionModel,
  DivisionSnapshot,
} from '@/lib/models/DivisionModel';
import { StateService } from '@/lib/StateService';
import { firestoreSubscribeList } from '@/lib/Util';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

interface State {
  readonly snapshots: Observable<DivisionSnapshot[]>;
}

/** manager list division */
export class DivisionListService implements StateService<State> {
  // mut snapshots
  readonly #source = {
    snapshots: new BehaviorSubject<DivisionSnapshot[]>([]),
  };

  readonly state: State;

  constructor() {
    this.state = {
      snapshots: this.#source.snapshots.asObservable(),
    }
  }

  listen() {
    const q = new DivisionModel().collection.orderBy('created_at', 'desc');
    return firestoreSubscribeList(q).pipe(
      tap(docs => this.#source.snapshots.next(docs)),
    );
  }

  async create(name: string) {
    const date = FbTimestamp.fromDate(new Date());
    const doc: DivisionDoc = {
      created_at: date,
      lines: [],
      name,
    };
    return new DivisionModel().collection.add(doc);
  }

  async updateName(id: string, name: string) {
    return new DivisionModel().collection.doc(id).update({ name });
  }

  async remove(id: string) {
    return new DivisionModel().collection.doc(id).delete();
  }

  async createLine(id: string, content: string) {
    const snapshot = await this.state.snapshots
      .pipe(
        take(1),
        map(snapshots => snapshots.find(d => d.id === id)),
      )
      .toPromise();

    if (!snapshot) return;

    const data = snapshot.data() as DivisionDoc;

    data.lines.push(content);
    return new DivisionModel().collection.doc(id).update({ lines: data.lines });
  }

  async updateLine(id: string, offset: number, content: string) {
    const snapshot = await this.state.snapshots
      .pipe(
        take(1),
        map(snapshots => snapshots.find(d => d.id === id)),
      )
      .toPromise();

    if (!snapshot) return;

    const data = snapshot.data() as DivisionDoc;
    data.lines.splice(offset, 1, content);
    return new DivisionModel().collection.doc(id).update({ lines: data.lines });
  }

  async removeLine(id: string, offset: number) {
    const snapshot = await this.state.snapshots
      .pipe(
        take(1),
        map(snaps => snaps.find(d => d.id === id)),
      )
      .toPromise();

    if (!snapshot) return;

    const data = snapshot.data() as DivisionDoc;
    data.lines.splice(offset, 1);
    return new DivisionModel().collection.doc(id).update({ lines: data.lines });
  }
}
