





















import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';
import Navbar from './components/navbar/Navbar.vue';
import { provideRouter, useRouter } from '@/router/use-router';
import { useCiService } from './lib/Ci';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import IconLoaderBar from './components/icon/loader/IconLoaderBar.vue';

export default defineComponent({
  components: { Navbar, IconLoaderBar },
  setup() {
    // provide router wrapped with reactive composition api,
    // nested component use [useRouter] function to invoke same reactive
    // router object.
    provideRouter();

    const authS = useCiService('AuthService');
    const router = useRouter();

    // hide navbar with pages no require login
    const unprotectedRouteNames = ['signin'];

    const showNavbar = computed(
      () => !unprotectedRouteNames.includes(router.currentRoute.name ?? ''),
    );

    // wait router & auth channel setup done before allow access pages
    //#region
    const ready = ref(false);
    // wrap router callback with observable
    const routerReadySource = new Observable((out) =>
      router.onReady(() => {
        out.next();
        out.complete();
      }),
    );

    const readySource = forkJoin([
      authS.eventChannel().pipe(take(1)),
      routerReadySource,
    ]);

    const readyPipe = readySource.pipe(tap(() => (ready.value = true)));

    let readySub: Subscription;
    onMounted(() => (readySub = readyPipe.subscribe()));
    onBeforeUnmount(() => readySub.unsubscribe());
    //#endregion

    // init subscription to auth event channel.
    let authSub: Subscription;
    onMounted(() => (authSub = authS.eventChannel().subscribe()));
    onBeforeUnmount(() => authSub.unsubscribe());

    const s = {
      showNavbar,
      ready,
    };
    return s;
  },
});
